import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSessionCouponCode, setCouponCodeReadOnly } from 'ducks/scheduling';
import webinarService from 'services/api/webinarService';
import authService from 'services/api/authService';
import schedulingService from 'services/api/schedulingService';
import {
  TestConsultation,
  ReviewConsultation,
  CurbsideConsultation,
  GetStartedConsultation,
} from 'constants/ServiceTypes';
import {
  setReferralSource,
  clearReferralSource,
  setWarmReferralSource,
  setWarmReferralURL,
  clearWarmReferralSource,
  clearWarmReferralURL,
  setReferralPartnerName,
  removeStage,
} from 'services/misc/ReferralSource';
import fetchHOC from 'components/high-order-components/FetchHOC';
import _ from 'lodash';
import { setStage } from 'ducks/registrationWizard';
import gaTrack, { GA_TR_AFFILIATION_REGISTRATION_PAGE } from '../../../../services/gaTrack';

class RegistrationEntryPoint extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    partners: PropTypes.object.isRequired,
    location: PropTypes.object,
  };

  componentDidMount() {
    gaTrack(GA_TR_AFFILIATION_REGISTRATION_PAGE);
    const {
      match: { params },
      location: { search },
      history,
      dispatch,
    } = this.props;
    let newUrl = '/signup/account';
    const partner = params.partner && params.partner.toLowerCase();
    const queryParams = new URLSearchParams(search);
    const query = {
      service: queryParams.get('service'),
      code: queryParams.get('code'),
      referral_url: queryParams.get('referral_url'),
      payment_mode: queryParams.get('payment_mode'),
    };
    this.clearSessionStorage();
    dispatch(setStage(undefined));

    if (query && query.referral_url) {
      let warm_referral_src = query.referral_url
        .replace('http://', '')
        .replace('https://', '')
        .replace('www.', '')
        .split(/[/?#]/)[0];
      setWarmReferralURL(query.referral_url);
      setWarmReferralSource(warm_referral_src);
    }

    if (query && query.code) {
      dispatch(setSessionCouponCode(query.code, true));
      dispatch(setCouponCodeReadOnly(true));
    }

    if (partner) {
      this.props.partners.fetch().then(() => {
        const { partners } = this.props;
        const partnersData = _.get(partners, 'data', []);
        let knownPartner = partnersData.find(
          (a) =>
            a.registration_url &&
            a.registration_url !== null &&
            a.registration_url.toLowerCase() === partner
        );
        const showMedicareQuestion = knownPartner?.showMedicareQuestion;
        if (_.isEmpty(knownPartner)) {
          // no idea what to do here.
          //TODO: maybe it makes sense to show some error
          history.replace('/');
          return;
        } else if (
          knownPartner.self_registration_enabled &&
          knownPartner.self_registration_enabled === true
        ) {
          this.savePaymentMode(query.payment_mode);
          this.savePartnerDetail(knownPartner);
          this.savePreselectedService(query.service);
        }
        this.saveShowMedicareMedicare(showMedicareQuestion);
        this.redirectToScheduling(newUrl);
      });
    } else {
      this.redirectToScheduling(newUrl);
    }
  }

  savePreselectedService(service) {
    if (service) {
      switch (service) {
        case 'testing-guidance':
          service = TestConsultation;
          break;
        case 'results-review':
          service = ReviewConsultation;
          break;
        case 'curbside-chat15':
          service = CurbsideConsultation;
          break;
        case 'getstarted':
          service = GetStartedConsultation;
          break;
      }
      authService.savePreselectedService(service);
    } else authService.resetPreselectedService();
  }

  savePartnerDetail = (knownPartner) => {
    if (knownPartner.display_name) setReferralSource(knownPartner.display_name);
    if (knownPartner.name) setReferralPartnerName(knownPartner.name);
    authService.saveAffiliationId(knownPartner.id);
    authService.saveAffiliation(knownPartner);
  };

  savePaymentMode = (payment_mode) => {
    if (payment_mode) {
      let defaultPaymentMode;
      switch (payment_mode) {
        case 'self-pay':
          defaultPaymentMode = 'self-pay';
          break;
        case 'insurance':
          defaultPaymentMode = 'insurance';
          break;
      }
      if (defaultPaymentMode) {
        schedulingService.setDefaultPaymentMode(defaultPaymentMode);
      }
    }
  };

  saveShowMedicareMedicare = (showMedicareQuestion) => {
    if (showMedicareQuestion) {
      schedulingService.setShowMedicare(showMedicareQuestion.toString());
    }
  };

  redirectToScheduling(url) {
    const { history } = this.props;
    history.replace(url);
  }

  clearSessionStorage() {
    removeStage();
    schedulingService.clearCouponCode();
    clearReferralSource();
    clearWarmReferralSource();
    clearWarmReferralURL();
    authService.resetAffiliation();
    authService.resetAffiliationId();
    authService.resetPreselectedService();
    schedulingService.removeShowMedicare();
  }

  render() {
    return <div />;
  }
}

export default connect()(
  fetchHOC({
    partners: () => webinarService.getPartners(),
  })(RegistrationEntryPoint)
);
