import * as Sentry from '@sentry/react';

import usersService from 'services/api/usersService';
import insuranceService from 'services/api/insuranceService';
import authService from 'services/api/authService';
import api from 'services/api/api';

import { loadDocuments } from 'ducks/documents';
import { loadCurrentAppointment } from 'ducks/appointment';
import { getPurchasedPackages } from 'ducks/misc/userServicePackages';
import {
  checkHelixReferral,
  setDefaultVseeSpecialty,
  setDefaultStateAndTimezone,
} from 'ducks/scheduling';
import { loadCurrentSCPEncounter } from 'ducks/scpencounters';
import { showLoader, closeLoader } from 'ducks/ui';
import { getShippingAddress, getHomeAddress } from 'services/utils';
import { GetStateTimezone } from 'services/geo';
import GrailStatusEnums from 'constants/GrailStatus';
import _ from 'lodash';
import { getCountryState } from './enums';

const ME_REQUEST = 'gm/auth/user/ME_REQUEST';
const ME_SUCCESS = 'gm/auth/user/ME_SUCCESS';
const ME_FAILURE = 'gm/auth/user/ME_FAILURE';

const LOAD_INSURANCE_REQUEST = 'gm/auth/user/LOAD_INSURANCE_REQUEST';
const LOAD_INSURANCE_SUCCESS = 'gm/auth/user/LOAD_INSURANCE_SUCCESS';
const LOAD_INSURANCE_FAILURE = 'gm/auth/user/LOAD_INSURANCE_FAILURE';
const LOAD_INSURANCE_COMPANIES_SUCCESS = 'gm/auth/user/LOAD_INSURANCE_COMPANIES_SUCCESS';

const LOAD_HOME_ADDRESS_REQUEST = 'gm/auth/user/LOAD_HOME_ADDRESS_REQUEST';
const LOAD_HOME_ADDRESS_SUCCESS = 'gm/auth/user/LOAD_HOME_ADDRESS_SUCCESS';
const LOAD_HOME_ADDRESS_FAILURE = 'gm/auth/user/LOAD_HOME_ADDRESS_FAILURE';

const LOAD_ALL_ADDRESS_REQUEST = 'gm/auth/user/LOAD_ALL_ADDRESS_REQUEST';
const LOAD_ALL_ADDRESS_SUCCESS = 'gm/auth/user/LOAD_ALL_ADDRESS_SUCCESS';
const LOAD_ALL_ADDRESS_FAILURE = 'gm/auth/user/LOAD_ALL_ADDRESS_FAILURE';

const LOAD_PAYMENT_METHOD_REQUEST = 'gm/auth/user/LOAD_PAYMENT_METHOD_REQUEST';
const LOAD_PAYMENT_METHOD_SUCCESS = 'gm/auth/user/LOAD_PAYMENT_METHOD_SUCCESS';
const LOAD_PAYMENT_METHOD_FAILURE = 'gm/auth/user/LOAD_PAYMENT_METHOD_FAILURE';
const UPDATE_PAYMENT_METHOD_REQUEST = 'gm/auth/user/UPDATE_PAYMENT_METHOD_REQUEST';
const UPDATE_PAYMENT_METHOD_SUCCESS = 'gm/auth/user/UPDATE_PAYMENT_METHOD_SUCCESS';
const UPDATE_PAYMENT_METHOD_FAILURE = 'gm/auth/user/UPDATE_PAYMENT_METHOD_FAILURE';

const ACC_VERIFICATION_REQUEST = 'gm/auth/user/ACC_VERIFICATION_REQUEST';
const ACC_VERIFICATION_SUCCESS = 'gm/auth/user/ACC_VERIFICATION_SUCCESS';
const ACC_VERIFICATION_FAILURE = 'gm/auth/user/ACC_VERIFICATION_FAILURE';
const ACC_VERIFICATION_CLEAR = 'gm/auth/user/ACC_VERIFICATION_CLEAR';
const RESET_ALL = 'gm/auth/user/RESET_ALL';

const SCP_MIGRATE_REQUEST = 'gm/auth/user/SCP_MIGRATE_REQUEST';
const SCP_MIGRATE_SUCCESS = 'gm/auth/user/SCP_MIGRATE_SUCCESS';
const SCP_MIGRATE_FAILURE = 'gm/auth/user/SCP_MIGRATE_FAILURE';

const SWITCH_AFFILITAION_REQUEST = 'gm/auth/user/SWITCH_AFFILITAION_REQUEST';
const SWITCH_AFFILITAION_SUCCESS = 'gm/auth/user/SWITCH_AFFILITAION_SUCCESS';
const SWITCH_AFFILITAION_FAILURE = 'gm/auth/user/SWITCH_AFFILITAION_FAILURE';

const UPDATE_HOLD_LOGGEDIN = 'gm/auth/user/UPDATE_HOLD_LOGGEDIN';
const ME_HOME_INFO = 'gm/auth/user/ME_HOME_INFO';
const ME_HOME_INFO_SUCCESS = 'gm/auth/user/ME_HOME_INFO_SUCCESS';
const ME_HOME_INFO_ERROR = 'gm/auth/user/ME_HOME_INFO_ERROR';

const GRAIL_GALLERI_SUCCESS = 'gm/auth/user/GRAIL_GALLERI_SUCCESS';
const GRAIL_GALLERI_FAILURE = 'gm/auth/user/GRAIL_GALLERI_FAILURE';
const GRAIL_GALLERI_RESET = 'gm/auth/user/GRAIL_GALLERI_RESET';

const GRAIL_QUESTIONNAIRE_SUCCESS = 'gm/auth/user/GRAIL_QUESTIONNAIRE_SUCCESS';
const GRAIL_QUESTIONNAIRE_FAILURE = 'gm/auth/user/GRAIL_QUESTIONNAIRE_FAILURE';
const GRAIL_QUESTIONNAIRE_RESET = 'gm/auth/user/GRAIL_QUESTIONNAIRE_RESET';

const PATIENT_CONSENT_REQUEST = 'gm/auth/user/PATIENT_CONSENT_REQUEST';
const PATIENT_CONSENT_SUCCESS = 'gm/auth/user/PATIENT_CONSENT_SUCCESS';
const PATIENT_CONSENT_FAILURE = 'gm/auth/user/PATIENT_CONSENT_FAILURE';

const SETUP_STRIPE_INTENT_REQUEST = 'gm/auth/user/SETUP_STRIPE_INTENT_REQUEST';
const SETUP_STRIPE_INTENT_SUCCESS = 'gm/auth/user/SETUP_STRIPE_INTENT_SUCCESS';
const SETUP_STRIPE_INTENT_FAILURE = 'gm/auth/user/SETUP_STRIPE_INTENT_FAILURE';

const SETUP_STRIPE_INTENT_FROM_EMAIL_REQUEST =
  'gm/auth/user/SETUP_STRIPE_INTENT_FROM_EMAIL_REQUEST';
const SETUP_STRIPE_INTENT_FROM_EMAIL_SUCCESS =
  'gm/auth/user/SETUP_STRIPE_INTENT_FROM_EMAIL_SUCCESS';
const SETUP_STRIPE_INTENT_FROM_EMAIL_FAILURE =
  'gm/auth/user/SETUP_STRIPE_INTENT_FROM_EMAIL_FAILURE';

export const REQUEST_LOGOUT = 'gm/auth/user/REQUEST_LOGOUT';
export const REQUEST_AUTHORIZATION_STATUS = 'gm/auth/user/REQUEST_AUTHORIZATION_STATUS';

const initialState = {
  me: null,
  meLoading: false,
  insurance: null,
  homeAddress: null,
  allAddress: [],
  insuranceCompanies: [],
  insuranceLoading: false,
  insuranceUpdating: false,
  paymentMethod: null,
  paymentUpdating: null,
  paymentMethodLoading: null,
  payments: null,
  error: null,
  requestEmailSended: null,
  homeAddressLoaded: false,
  allAddressLoaded: false,
  scpMigrating: false,
  scpMigrateError: null,
  holdLoggedIn: false,
  gettingHomeInfo: false,
  homeInfo: null,
  grailGalleriSuccess: null,
  grailGalleriFailure: null,
  grailQuestionnaireSuccess: null,
  grailQuestionnaireFailure: null,
  switchingAffiliation: false,
  switchAffiliationError: null,
  patientConsentLoading: false,
  patientConsentFailure: null,
  requestLogout: false,
  authorizationStatus: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ME_REQUEST:
      return {
        ...state,
        meLoading: true,
        error: null,
      };
    case LOAD_HOME_ADDRESS_REQUEST:
      return {
        ...state,
        homeAddressLoaded: false,
        error: null,
      };
    case LOAD_HOME_ADDRESS_SUCCESS:
      return {
        ...state,
        homeAddress: action.payload,
        error: null,
        homeAddressLoaded: true,
      };
    case LOAD_ALL_ADDRESS_REQUEST:
      return {
        ...state,
        allAddressLoaded: false,
        error: null,
      };
    case LOAD_ALL_ADDRESS_SUCCESS:
      return {
        ...state,
        allAddress: action.payload,
        error: null,
        allAddressLoaded: true,
      };
    case LOAD_INSURANCE_REQUEST:
      return {
        ...state,
        insuranceLoading: true,
        error: null,
      };
    case LOAD_INSURANCE_SUCCESS:
      return {
        ...state,
        insurance: action.payload,
        error: null,
      };
    case LOAD_INSURANCE_COMPANIES_SUCCESS:
      return {
        ...state,
        insuranceCompanies: action.payload,
        error: null,
      };
    case LOAD_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        paymentMethodLoading: true,
        error: null,
      };
    case UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentUpdating: false,
        error: null,
      };
    case ME_SUCCESS:
      return {
        ...state,
        me: action.payload,
        meLoading: false,
        error: null,
      };
    case LOAD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentMethod: action.payload,
        paymentMethodLoading: false,
        error: null,
      };
    case UPDATE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        paymentUpdating: true,
        error: null,
      };
    case ME_FAILURE:
      return {
        ...state,
        me: null,
        meLoading: false,
        error: action.payload,
      };
    case LOAD_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        paymentMethod: null,
        paymentMethodLoading: false,
        error: action.payload,
      };
    case UPDATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        paymentUpdating: false,
        paymentMethod: null,
        error: action.payload,
      };
    case RESET_ALL:
      return { ...initialState };
    case ACC_VERIFICATION_REQUEST:
      return {
        ...state,
      };
    case ACC_VERIFICATION_SUCCESS:
      return {
        ...state,
        requestEmailSended: true,
      };
    case ACC_VERIFICATION_FAILURE:
      return {
        ...state,
        requestEmailSended: false,
        requestEmailError: action.payload,
      };
    case ACC_VERIFICATION_CLEAR:
      return {
        ...state,
        requestEmailSended: undefined,
      };
    case SCP_MIGRATE_REQUEST:
      return {
        ...state,
        scpMigrating: true,
        scpMigrateError: null,
      };
    case SCP_MIGRATE_SUCCESS:
      return {
        ...state,
        scpMigrating: false,
        scpMigrateError: null,
      };
    case SCP_MIGRATE_FAILURE:
      return {
        ...state,
        scpMigrating: false,
        scpMigrateError: action.payload,
      };

    case SWITCH_AFFILITAION_REQUEST:
      return {
        ...state,
        switchingAffiliation: true,
        switchAffiliationError: null,
      };
    case SWITCH_AFFILITAION_SUCCESS:
      return {
        ...state,
        switchingAffiliation: false,
        switchAffiliationError: null,
      };
    case SWITCH_AFFILITAION_FAILURE:
      return {
        ...state,
        switchingAffiliation: false,
        switchAffiliationError: action.payload,
      };

    case UPDATE_HOLD_LOGGEDIN:
      return {
        ...state,
        holdLoggedIn: action.payload,
      };
    case ME_HOME_INFO:
      return {
        ...state,
        gettingHomeInfo: true,
        homeInfo: null,
      };
    case ME_HOME_INFO_SUCCESS:
      return {
        ...state,
        gettingHomeInfo: false,
        homeInfo: action.payload,
      };
    case ME_HOME_INFO_ERROR:
      return {
        ...state,
        gettingHomeInfo: false,
        homeInfo: null,
      };
    case GRAIL_GALLERI_SUCCESS:
      return {
        ...state,
        grailGalleriSuccess: action.payload || true,
        grailGalleriFailure: null,
      };
    case GRAIL_GALLERI_FAILURE:
      return {
        ...state,
        grailGalleriSuccess: null,
        grailGalleriFailure: action.payload,
      };
    case GRAIL_GALLERI_RESET:
      return {
        ...state,
        grailGalleriSuccess: null,
        grailGalleriFailure: null,
      };
    case GRAIL_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        grailQuestionnaireSuccess: action.payload || true,
        grailQuestionnaireFailure: null,
      };
    case GRAIL_QUESTIONNAIRE_FAILURE:
      return {
        ...state,
        grailQuestionnaireSuccess: null,
        grailQuestionnaireFailure: action.payload,
      };
    case GRAIL_QUESTIONNAIRE_RESET:
      return {
        ...state,
        grailQuestionnaireSuccess: null,
        grailQuestionnaireFailure: null,
      };

    case PATIENT_CONSENT_REQUEST:
      return {
        ...state,
        patientConsentRequest: true,
        patientConsentFailure: null,
      };
    case PATIENT_CONSENT_SUCCESS:
      return {
        ...state,
        patientConsentRequest: false,
        patientConsentFailure: null,
      };
    case PATIENT_CONSENT_FAILURE:
      return {
        ...state,
        patientConsentRequest: false,
        patientConsentFailure: action.payload,
      };

    case SETUP_STRIPE_INTENT_REQUEST:
      return {
        ...state,
        stripeIntentLoading: true,
        stripeIntentFailure: null,
      };
    case SETUP_STRIPE_INTENT_SUCCESS:
      return {
        ...state,
        stripeIntentLoading: false,
        stripeIntent: action.payload,
      };
    case SETUP_STRIPE_INTENT_FAILURE:
      return {
        ...state,
        stripeIntentLoading: false,
        stripeIntentFailure: action.payload,
      };

    case SETUP_STRIPE_INTENT_FROM_EMAIL_REQUEST:
      return {
        ...state,
        stripeIntentFromEmailLoading: true,
        stripeIntentFromEmailFailure: null,
      };
    case SETUP_STRIPE_INTENT_FROM_EMAIL_SUCCESS:
      return {
        ...state,
        stripeIntentFromEmailLoading: false,
        stripeIntentFromEmail: action.payload,
      };
    case SETUP_STRIPE_INTENT_FROM_EMAIL_FAILURE:
      return {
        ...state,
        stripeIntentFromEmailLoading: false,
        stripeIntentFromEmailFailure: action.payload,
      };
    case REQUEST_LOGOUT:
      return {
        ...state,
        requestLogout: action.payload,
      };
    case REQUEST_AUTHORIZATION_STATUS:
      return {
        ...state,
        authorizationStatus: action.payload,
      };

    default:
      return state;
  }
}

export function meRequest() {
  return { type: ME_REQUEST };
}

export function meSuccess(profile) {
  return { type: ME_SUCCESS, payload: profile };
}

export function meFailure(error) {
  return { type: ME_FAILURE, payload: error };
}

export function requestAuthorizationStatus(payload) {
  return { type: REQUEST_AUTHORIZATION_STATUS, payload };
}

export function requestLogoutStatus(payload) {
  return { type: REQUEST_LOGOUT, payload };
}

export function me() {
  return (dispatch, getState) => {
    if (getState().user.meLoading) return;
    return dispatch(meLoadProfile()).then((res) => {
      if (res && res.role === 'patient') {
        dispatch(getCountryState(res.partnerUUID));

        dispatch(loadDocuments());
        dispatch(loadCurrentAppointment()).then(() => dispatch(checkHelixReferral()));
        dispatch(getPurchasedPackages());
        dispatch(loadCurrentSCPEncounter());
      }
    });
  };
}

export function meLoadProfile() {
  return (dispatch, getState) => {
    if (getState().user.meLoading) return;
    dispatch(meRequest());
    return usersService
      .getMyProfile()
      .then((user) => {
        Sentry.setUser({
          id: user.id,
          email: user.email,
        });
        dispatch(meSuccess(user));
        dispatch(setDefaultVseeSpecialty(user.vsee_specialty));
        return user;
      })
      .catch((error) => {
        Sentry.setUser(null);
        dispatch(meFailure(error));
        // Unfortunately, for now we can't use 'instanceof' for extended Error class in Babel, error.name is the substitution
        // No status for errors, thrown before response handled
        if (error.name === 'ResponseError' && (error.status === 401 || !error.status)) {
          dispatch(requestAuthorizationStatus(false));
        }
      });
  };
}

export function requestVefiricationStarted() {
  return { type: ACC_VERIFICATION_REQUEST };
}

export function requestVefiricationSuccess() {
  return { type: ACC_VERIFICATION_SUCCESS };
}

export function requestVefiricationFailure(error) {
  return { type: ACC_VERIFICATION_FAILURE, payload: error };
}

export function clearRequestResults() {
  return { type: ACC_VERIFICATION_CLEAR };
}

export function loadInsuranceRequest() {
  return { type: LOAD_INSURANCE_REQUEST };
}

export function loadInsuranceSuccess(data) {
  return { type: LOAD_INSURANCE_SUCCESS, payload: data };
}

export function loadInsuranceCompaniesSuccess(data) {
  return { type: LOAD_INSURANCE_COMPANIES_SUCCESS, payload: data };
}

export function loadInsuranceFailure(error) {
  return { type: LOAD_INSURANCE_FAILURE, payload: error };
}

export function loadHomeAddressRequest() {
  return { type: LOAD_HOME_ADDRESS_REQUEST };
}

export function loadHomeAdddressSuccess(data) {
  return { type: LOAD_HOME_ADDRESS_SUCCESS, payload: data };
}

export function loadHomeAdddressFailure(data) {
  return { type: LOAD_HOME_ADDRESS_FAILURE, payload: data };
}

export function loadAllAddressRequest() {
  return { type: LOAD_ALL_ADDRESS_REQUEST };
}

export function loadAllAdddressSuccess(data) {
  return { type: LOAD_ALL_ADDRESS_SUCCESS, payload: data };
}

export function loadAllAdddressFailure(data) {
  return { type: LOAD_ALL_ADDRESS_FAILURE, payload: data };
}

export function loadPaymentMethodRequest() {
  return { type: LOAD_PAYMENT_METHOD_REQUEST };
}

export function loadPaymentMethodSuccess(data) {
  return { type: LOAD_PAYMENT_METHOD_SUCCESS, payload: data };
}

export function loadPaymentMethodFailure(error) {
  return { type: LOAD_PAYMENT_METHOD_FAILURE, payload: error };
}

export function updatePaymentMethodRequest(data) {
  return { type: UPDATE_PAYMENT_METHOD_REQUEST, payload: data };
}

export function updatePaymentMethodSuccess() {
  return { type: UPDATE_PAYMENT_METHOD_SUCCESS };
}

export function updatePaymentMethodFailure(error) {
  return { type: UPDATE_PAYMENT_METHOD_FAILURE, payload: error };
}

export function resetAll() {
  return { type: RESET_ALL };
}

export function scpMigrateRequest() {
  return { type: SCP_MIGRATE_REQUEST };
}

export function scpMigrateSuccess() {
  return { type: SCP_MIGRATE_SUCCESS };
}

export function scpMigrateFailure(error) {
  return { type: SCP_MIGRATE_FAILURE, payload: error };
}

export function patientConsentRequest() {
  return { type: PATIENT_CONSENT_REQUEST };
}

export function patientConsentSuccess() {
  return { type: PATIENT_CONSENT_SUCCESS };
}

export function patientConsentFailure(error) {
  return { type: PATIENT_CONSENT_FAILURE, payload: error };
}

export function swicthAffiliationRequest() {
  return { type: SWITCH_AFFILITAION_REQUEST };
}

export function swicthAffiliationSuccess() {
  return { type: SWITCH_AFFILITAION_SUCCESS };
}

export function swicthAffiliationFailure(error) {
  return { type: SWITCH_AFFILITAION_FAILURE, payload: error };
}

export function updateHoldLoggedIn(isHold) {
  return { type: UPDATE_HOLD_LOGGEDIN, payload: isHold };
}

export function getHomeInfoRequest() {
  return { type: ME_HOME_INFO };
}

export function getHomeInfoSuccess(data) {
  return { type: ME_HOME_INFO_SUCCESS, payload: data };
}

export function getHomeInfoError(error) {
  return { type: ME_HOME_INFO_ERROR, payload: error };
}

function grailGalleriSuccess(payload) {
  return { type: GRAIL_GALLERI_SUCCESS, payload };
}

function grailGalleriFailure(payload) {
  return { type: GRAIL_GALLERI_FAILURE, payload };
}

export function grailGalleriReset() {
  return { type: GRAIL_GALLERI_RESET };
}

function grailQuestionnareSuccess(payload) {
  return { type: GRAIL_QUESTIONNAIRE_SUCCESS, payload };
}

function grailQuestionnareFailure(payload) {
  return { type: GRAIL_QUESTIONNAIRE_FAILURE, payload };
}

export function grailQuestionnareReset() {
  return { type: GRAIL_QUESTIONNAIRE_RESET };
}

export function loadInsurance() {
  return (dispatch) => {
    dispatch(loadInsuranceRequest());
    return insuranceService
      .getInsurance()
      .then((data) => dispatch(loadInsuranceSuccess(data)))
      .catch((error) => {
        dispatch(loadInsuranceFailure(error));
        throw error;
      });
  };
}

export function loadHomeAddress() {
  return (dispatch) => {
    dispatch(loadHomeAddressRequest());
    return usersService
      .getMyAddress()
      .then((data) => data && data.find((a) => a.addressType == 'home'))
      .then((data) => dispatch(loadHomeAdddressSuccess(data)))
      .catch((error) => {
        dispatch(loadHomeAdddressFailure(error));
      });
  };
}

export function loadAllAddress() {
  return (dispatch, getState) => {
    dispatch(loadAllAddressRequest());
    return usersService
      .getMyAddress()
      .then((data) => {
        const { me } = getState().user;
        const countryIsNonUS = _.get(me, ['countryIsNonUS'], false);
        const shippingAddress = getShippingAddress(data);
        const homeAddress = getHomeAddress(data);
        const address = countryIsNonUS ? homeAddress : shippingAddress;
        if (address && !_.isEmpty(address)) {
          const timezone = GetStateTimezone(address.addressState);
          dispatch(
            setDefaultStateAndTimezone(address.addressCountry, address.addressState, timezone)
          );
        }
        dispatch(loadAllAdddressSuccess(data));
        return data;
      })
      .catch((error) => {
        dispatch(loadAllAdddressFailure(error));
      });
  };
}

export function loadInsuranceCompanies() {
  return (dispatch) => {
    dispatch(loadInsuranceRequest());
    return insuranceService
      .getInsuranceCompanies()
      .then((data) => dispatch(loadInsuranceCompaniesSuccess(data)))
      .catch((error) => {
        dispatch(loadInsuranceFailure(error));
        throw error;
      });
  };
}

export function loadPaymentMethod() {
  return (dispatch) => {
    dispatch(loadPaymentMethodRequest());
    return usersService
      .getMyPaymentMethod()
      .then((paymentMethod) => dispatch(loadPaymentMethodSuccess(paymentMethod)))
      .catch((error) => dispatch(loadPaymentMethodFailure(error)));
  };
}

export function updatePaymentMethod(data) {
  return (dispatch) => {
    dispatch(updatePaymentMethodRequest());
    return usersService
      .updateMyPaymentMethod(data)
      .then(() => dispatch(updatePaymentMethodSuccess()))
      .then(() => dispatch(updateUserAndPaymentMethod()))
      .catch((error) => {
        if (error.submissionError) throw error.submissionError;
        dispatch(updatePaymentMethodFailure(error));
        throw error;
      });
  };
}

export function requestVerificationMethod(data) {
  return (dispatch) => {
    dispatch(requestVefiricationStarted());
    return authService
      .requestAccountActivationEmail(data)
      .then(() => dispatch(requestVefiricationSuccess()))
      .catch((error) => dispatch(requestVefiricationFailure(error)));
  };
}

export function clearRequestActions() {
  return (dispatch) => {
    dispatch(clearRequestResults());
  };
}

export function updateUserAndPaymentMethod() {
  return (dispatch) => Promise.all([dispatch(meLoadProfile()), dispatch(loadPaymentMethod())]);
}

export function refreshToken() {
  return () => {
    const token = api.getToken();
    return authService.refreshAccessToken({ token }).then((data) => {
      api.setToken(data.token, true);
    });
  };
}

export function switchAffiliation(isNovartisaff) {
  const reqObj = isNovartisaff ? { switchNovartis: true } : { switchGrail: true };
  return (dispatch) => {
    dispatch(showLoader());
    dispatch(swicthAffiliationRequest());
    return usersService
      .updateMyPartner(reqObj)
      .then(() => dispatch(swicthAffiliationSuccess()))
      .then(() => dispatch(closeLoader()))
      .catch((error) => {
        dispatch(closeLoader());
        dispatch(swicthAffiliationFailure(error));
        if (error.submissionError) throw error.submissionError;
        throw error;
      });
  };
}

export function getHomeInfo() {
  return (dispatch) => {
    dispatch(getHomeInfoRequest());
    return usersService
      .getHomeInfo()
      .then((data) => dispatch(getHomeInfoSuccess(data)))
      .catch((error) => {
        dispatch(getHomeInfoError(error));
        if (error.submissionError) throw error.submissionError;
        throw error;
      });
  };
}

export function createTROEncounter(data) {
  return (dispatch) => {
    dispatch(showLoader());
    return usersService
      .createTROEncounter(data)
      .then((data) => {
        dispatch(closeLoader());
        dispatch(grailGalleriSuccess(data));
      })
      .catch((error) => {
        dispatch(closeLoader());
        if (error.submissionError) throw error.submissionError;
        dispatch(grailGalleriFailure(error.message));
        throw error;
      });
  };
}
export function grailQuesionnaire(data, affType) {
  return (dispatch) => {
    dispatch(showLoader());
    return usersService
      .grailQuesionnaire(data)
      .then((grailres) => {
        dispatch(grailQuestionnareSuccess(grailres));
        if (
          affType == 'Novartis_Galleri' &&
          _.get(grailres, ['grail_status'], '') == GrailStatusEnums.HIDE_CARD
        ) {
          window.location = '/patient/redirect-for-switch';
        } else if (
          affType == 'Illumina' &&
          _.get(grailres, ['grail_status'], '') == GrailStatusEnums.QUALIFICATION_REQUIRED
        ) {
          authService.saveGrailDisqualification(true);
          window.location = '/patient/switchaffiliation';
        }
        return dispatch(meLoadProfile()).then((res) => {
          dispatch(closeLoader());
          return res;
        });
      })
      .catch((error) => {
        dispatch(closeLoader());
        if (error.submissionError) throw error.submissionError;
        dispatch(grailQuestionnareFailure(error.message));
        throw error;
      });
  };
}

export function updateConsentStatus(data, loadMe = true) {
  return (dispatch) => {
    dispatch(patientConsentRequest());
    dispatch(showLoader());
    return usersService
      .updateConsentStatus(data)
      .then((res) => {
        dispatch(patientConsentSuccess());
        if (!loadMe) {
          return dispatch(closeLoader());
        }
        return dispatch(meLoadProfile()).then((res) => {
          dispatch(closeLoader());
          return res;
        });
      })
      .catch((error) => {
        dispatch(closeLoader());
        dispatch(patientConsentFailure(error));
        if (error.submissionError) throw error.submissionError;
        throw error;
      });
  };
}

export function StripeIntentRequest() {
  return { type: SETUP_STRIPE_INTENT_REQUEST };
}

export function StripeIntentSuccess(payload) {
  return { type: SETUP_STRIPE_INTENT_SUCCESS, payload };
}

export function StripeIntentFailure(error) {
  return { type: SETUP_STRIPE_INTENT_FAILURE, payload: error };
}

export function SetupStripeIntent() {
  return (dispatch) => {
    dispatch(StripeIntentRequest());
    dispatch(showLoader());
    return usersService
      .setupStripeIntent()
      .then((res) => {
        dispatch(StripeIntentSuccess(res));
        setTimeout(() => {
          dispatch(closeLoader());
        }, 500);
        return res;
      })
      .catch((error) => {
        dispatch(closeLoader());
        dispatch(StripeIntentFailure(error));
        throw error;
      });
  };
}

export function StripeIntentFromEmailRequest() {
  return { type: SETUP_STRIPE_INTENT_FROM_EMAIL_REQUEST };
}

export function StripeIntentFromEmailSuccess(payload) {
  return { type: SETUP_STRIPE_INTENT_FROM_EMAIL_SUCCESS, payload };
}

export function StripeIntentFromEmailFailure(error) {
  return { type: SETUP_STRIPE_INTENT_FROM_EMAIL_FAILURE, payload: error };
}

export function setupStripeIntentFromEmail(data) {
  return (dispatch) => {
    dispatch(StripeIntentFromEmailRequest());
    dispatch(showLoader());
    return usersService
      .setupStripeIntentFromEmail(data)
      .then((res) => {
        dispatch(StripeIntentFromEmailSuccess(res));
        setTimeout(() => {
          dispatch(closeLoader());
        }, 500);
        return res;
      })
      .catch((error) => {
        dispatch(closeLoader());
        dispatch(StripeIntentFromEmailFailure(error));
        throw error;
      });
  };
}
