import _ from 'lodash';
import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from 'containers/pages/patient/SCP/ProfilePage/CheckoutForm';
import './CreditCardDetail.scss';

const CreditCardDetail = ({
  clientSecret,
  loading,
  billingDetails: { name, country, postal_code },
  isMobile,
  handleComponentState,
}) => {
  const options = {
    clientSecret: clientSecret,
    appearance: {
      variables: {
        fontFamily: "'RionaSans-Medium', Arial, Helvetica, sans-serif",
        spacingUnit: '6px',
      },
      rules: {
        '.Label': {
          fontSize: '14px',
          textTransform: 'capitalize',
          lineHeight: '16.8px',
          color: '#525968',
        },
        '.Input': {
          padding: isMobile ? '11px 8px' : '8px 8px',
          fontSize: '13px',
          border: '1px solid #bac3ce',
          height: '40px',
        },
        '.Input:focus': {
          borderColor: '#70cbf1',
        },
        '.Input--invalid': {
          backgroundColor: 'rgba(254, 145, 137, 0.5)',
          boxShadow: 'none',
        },
        '.Error': {
          fontSize: '12px',
          color: '#ff0000',
        },
        '.TermsText': {
          fontSize: '0px',
          lineHeight: '0px',
          padding: '0px',
        },
      },
    },
  };

  return (
    <div className="card-section">
      <div className="section-header">Credit or Debit Card</div>
      {!loading && (
        <div className="form-row">
          <Elements
            stripe={loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)}
            options={options}
          >
            <CheckoutForm
              handleComponentState={handleComponentState}
              defaultValues={{
                billingDetails: {
                  name: name,
                  address: {
                    country,
                    postal_code,
                  },
                },
              }}
            />
          </Elements>
        </div>
      )}
      <span className="payment-terms-text">
        By providing your card information, you allow Genome Medical, Inc. to charge your card in
        accordance with their terms.
      </span>
    </div>
  );
};

export default CreditCardDetail;
